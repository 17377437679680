<template>
<div>
  <div  v-if="$store.state.show">
    <h2>Android平台</h2>
    <h3>1. 申请测试Key</h3>
    <p>TiSDK 的初始化接口，需要使用 Key 进行鉴权，需要向商务申请，申请方法：</p>
    <ul>
      <li>（1）拨打17136231898进行咨询。</li>
      <li>（2）加QQ3599350770 / 微信 17136231898进行咨询</li>
    </ul>
    <h3>2. 下载配置SDK</h3>
    <p>最新版本下载集成：</p>
    <ul class="ul_style">
      <li>
        SDK下载地址：<a href="https://gitee.com/tillusory/TiSDK_Android"
          >TiSDK</a
        >
      </li>
      <li>
        演示Dome下载地址：<a href="https://gitee.com/tillusory/Ti_Demo_Android">
          Ti_Demo_Android</a
        >
      </li>
    </ul>
    <h3>3. 检查资源</h3>
    <p>
      Android版本的 TiSDK，包含 <span>ti-sdk.aar</span>依赖库、
      <span>jniLibs</span>文件夹、<span>assets</span>资源文件、<span>tiui</span>
      依赖工程（可选）。 SDK资源示意图：
    </p>
    <img src="@/assets/developer/android1.png" alt="" />
    <h3>4. 导入工程</h3>
    <ul>
      <li>
        （1）将 <span>ti-sdk.aar</span>文件拷贝到
        <span>app</span>模块中的
        <span>libs </span>文件夹下，并在 app 模块的
        <span>build.gradle</span> 文件的
        <span>dependencies</span> 中，增加如下依赖：
        <pre v-highlightA>
          <code>
            dependencies {
              implementation files('libs/ti-sdk.aar')
            }
          </code>
        </pre>
      </li>
      <li>
        （2）将<span>jniLibs</span>文件夹中，各个<span>ABI</span>对应的
        <span>libTiSDK.so</span>文件，拷贝到对应目录中；
      </li>
      <li>（3）将<span>assets</span>资源文件拷贝到项目的对应目录中；</li>
    </ul>
    <h3>5.使用TiUI（开源，可选）</h3>
    <p>TiUI 是开源的，可参考选用。 使用 TiUI 方法如下:</p>
    <ul>
      <li>
        （1）依赖我们的 <span>tiui</span> 工程，使用我们提供的开源
        <span>UI</span> 库，将
        <span>tiui</span> 文件夹拷贝到工程根目录下，在工程根目录的
        <span>settings.gradle</span>
        文件中，增加如下代码：
        <pre v-highlightA>
          <code>
            include ':tiui'
          </code>
        </pre>
      </li>
      <li>
        （2）在 <span>app</span> 模块中的 <span>build.gradle</span> 文件的
        <span>dependencies</span>中，增加如下代码：
        <pre v-highlightA>
          <code>
            implementation project(':tiui')
          </code>
        </pre>
      </li>
    </ul>
    <h3>6. 集成开发</h3>
    <p>
      都成功导入工程后就可以进行集成开发了，如果过程中遇到问题，也可寻求技术支持。
    </p>
    <h3>6.1 初始化</h3>
    <p>
      TiSDK 初始化函数程序中调用一次即可生效，建议用户在 Application
      创建的时候调用;如果渲染功能使用不频繁，也可以在使用的时候调用，接口如下:
    </p>
    <pre v-highlightA>
      <code>
        //在线鉴权初始化方法
           TiSDK.init("your key", context);  
      </code>
    </pre>
    <p>
      TiSDK 有初始化日志，在日志栏搜索
      <span>InitStatus</span>，可查看版本信息和初始化状态。
    </p>
    <h3>6.2 渲染步骤</h3>
    <ul>
      <li>
        （1）设置使用 <span>tiui</span> 的 <span>Activity</span> 继承
        <span>FragmentActivity</span>，例如：
        <pre v-highlightA>
          <code>
            public class CameraActivity extends FragmentActivity {
                //...
            }
          </code>
        </pre>
      </li>
      <li>
        （2）使用 <span>TiSDKManager</span>的 <span>getInstance</span>方法得到
        <span>TiSDKManager</span> 管理器对象，所有的渲染都必须通过
        <span>TiSDKManager</span> 对象调用，代码如下：
        <pre v-highlightA>
          <code>
            addContentView(new TiPanelLayout(this).init(TiSDKManager.getInstance()),
            new FrameLayout.LayoutParams(ViewGroup.LayoutParams.MATCH_PARENT, ViewGroup.LayoutParams.MATCH_PARENT));
          </code>
        </pre>
      </li>
      <li>
        （3）渲染 GL_TEXTURE_EXTERNAL_OES 纹理，调用
        <span>TiSDKManager</span> 的 <span>renderOESTexture</span> 方法，返回
        GL_TEXTURE_EXTERNAL_OES类型的纹理 <span>Id</span>，代码如下：
        <pre v-highlightA>
          <code>
            int textureId = TiSDKManager.getInstance().renderOESTexture(
            oesTextureId, //oes纹理Id
            imageWidth, //图像宽度
            imageHeight, //图像高度
            rotation, //TiRotation枚举，图像顺时针旋转的角度
            isMirror//图像是否左右镜像
            );
          </code>
        </pre>
      </li>
      <li>
        （4）渲染 GL_TEXTURE_2D的纹理，调用 <span>TiSDKManager</span>的<span
          >renderTexture2D</span
        >
        方法，返回 GL_TEXTURE_2D类型的纹理<span>Id</span>，代码如下：
        <pre v-highlightA>
          <code>
            int textureId = TiSDKManager.getInstance().renderTexture2D(
            texture2D,//2D纹理Id
            imageWidth,//图像宽度
            imageHeight,//图像高度
            rotation,//TiRotation枚举，图像顺时针旋转的角度
            isMirror//图像是否左右镜像
            );
          </code>
        </pre>
      </li>
      <li>
        （5）渲染 byte[] 视频帧，调用 <span>TiSDKManager</span> 的
        <span>renderPixels</span>
        方法，渲染视频帧，代码如下：
        <pre v-highlightA>
          <code>
            TiSDKManager.getInstance().renderPixels(
            data,//视频帧数组
            format,//视频帧格式，NV21 or RGBA
            imageWidth,//图像宽度
            imageHeight,//图像高度
            rotation,//TiRotation枚举，图像顺时针旋转的角度
            isMirror//图像是否左右镜像
            );
          </code>
        </pre>
      </li>
    </ul>
    <h3>6.3 销毁释放</h3>
    <p>
      渲染结束，要释放渲染资源，否则会造成内存泄漏，一般随着渲染数据回调接口的销毁释放，或者随着当前<span
        >Activity</span
      >
      或者 <span>Fragment</span> 的生命周期结束释放。 释放资源调用
      <span>TiSDKManager</span> 的 <span>destroy</span> 方法，代码如下：
      <pre v-highlightA>
          <code>
            TiSDKManager.getInstance().destroy();
          </code>
      </pre>
    </p>
    <h3>6.4 资源配置到自己的服务器</h3>
    <p>TiSDK所有资源均支持放在用户自己的服务器，使用以下方法进行设置：</p>
    <pre v-highlightA>
      <code>
        TiSDK.setStickerUrl(String url);//贴纸资源根目录
        TiSDK.setStickerThumbUrl(String url);//贴纸封面图根目录
        TiSDK.setInteractionUrl(String url);//互动贴纸资源根目录
        TiSDK.setInteractionThumbUrl(String url);//互动贴纸封面图根目录
        TiSDK.setGiftUrl(String url);//礼物资源根目录
        TiSDK.setGiftThumbUrl(String url);//礼物封面图根目录
        TiSDK.setWatermarkUrl(String url);//水印资源根目录
        TiSDK.setWatermarkThumbUrl(String url);//水印图封面根目录
        TiSDK.setMaskUrl(String url);//面具资源根目录
        TiSDK.setMaskThumbUrl(String url);//面具封面根目录
        TiSDK.setGreenScreenUrl(String url);//绿幕资源目录
        TiSDK.setGreenScreenThumbUrl(String url);//绿幕封面根目录
        TiSDK.setMakeupUrl(String url);//美妆资源目录
        TiSDK.setMakeupThumbUrl(String url);//美妆封面根目录
        TiSDK.setPortraitUrl(String url);//人像抠图资源目录
        TiSDK.setPortraitThumbUrl(String url);//人像抠图封面根目录
      </code>
    </pre>
    <h3>6.5日志功能</h3>
    <p>可采用以下方法关闭或打开SDK调试日志</p>
    <pre v-highlightA>
      <code>
        TiSDK.setLog(boolean enable);//true:打开日志 false:关闭日志
      </code>
    </pre>
  </div>
  <div  v-else>
    <h2>Android Platform</h2>
    <h3>1. Apply for Key to test</h3>
    <p>TiSDK’s initialization interface needs to use Key to authenticate, so you should apply to us for a key. The application methods are as follows:</p>
    <ul>
      <li>（1）call 17136231898 for consultation.</li>
      <li>（2）add QQ:599350770 | WeChat:17136231898 to consult.</li>
    </ul>
    <h3>2. Download SDK</h3>
    <ul class="ul_style">
      <li>
        SDK download address：<a href="https://gitee.com/tillusory/TiSDK_Android"
          >TiSDK</a
        >
      </li>
      <li>
        Demo download address：<a href="https://gitee.com/tillusory/Ti_Demo_Android">
          Ti_Demo_Android</a
        >
      </li>
    </ul>
    <h3>3. Check Resources</h3>
    <p>
     TiSDK of android version includes  <span>ti-sdk.aar</span>kit, 
      <span>jniLibs</span>folder, <span>assets</span>folder and <span>tiui</span>
     dependent library(optional).
      SDK resources’ schematic diagram:
    </p>
    <img src="@/assets/developer/android1.png" alt="" />
    <h3>4. Import project</h3>
    <ul>
      <li>
        （1）copy<span>ti-sdk.aar</span>kit to 
        <span>libs </span>folder of
        <span>app</span> module. then, open
        <span>build.gradle</span> file and add the following code in
        <span>dependencies</span> block:
        <pre v-highlightA>
          <code>
            dependencies {
              implementation files('libs/ti-sdk.aar')
            }
          </code>
        </pre>
      </li>
      <li>
        （2）copy <span>libTiSDK.so</span> files in
        <span>jniLibs</span>folder to corresponding ABI directory.
      </li>
      <li>（3）copy resources in<span>assets</span>folder to corresponding directory.</li>
    </ul>
    <h3>5.Use TiUI（open-source, optional）</h3>
    <p>TiUI is open-source and optional to use.
      The application methods are as follows:</p>
    <ul>
      <li>
        （1）copy <span>tiui</span> folder to root directory of project and add the following code in
        <span>settings.gradle</span>
        file:
        <pre v-highlightA>
          <code>
            include ':tiui'
          </code>
        </pre>
      </li>
      <li>
        （2）open <span>build.gradle</span>  file in 
        <span>app</span>module and add the following code in 
        <span>dependencies</span>block:
        <pre v-highlightA>
          <code>
            implementation project(':tiui')
          </code>
        </pre>
      </li>
    </ul>
    <h3>6. Development</h3>
    <p>
      After importing project, you are able to start developing. If encountering problems, you can consult us.
    </p>
    <h3>6.1 Init</h3>
    <p>
      TiSDK initialization function needs to be called only once to take effect and we suggest calling it when Application is created. If rendering function is used not frequently, you can call it when using.
    </p>
    <pre v-highlightA>
      <code>
        //online authentication
        TiSDK.init("your key", context);
      </code>
    </pre>
    <p>
      TiSDK has logs about initialization and you can input 
      <span>InitStatus</span> in logcat to look over version information and initialization status.
    </p>
    <h3>6.2 Rendering step</h3>
    <ul>
      <li>
        （1）set <span>Activity</span> that uses<span>tiui</span>extends
        <span>FragmentActivity</span>, for example:
        <pre v-highlightA>
          <code>
            public class CameraActivity extends FragmentActivity {
                //...
            }
          </code>
        </pre>
      </li>
      <li>
        （2）use  <span>TiSDKManager.getInstance</span>function to get singleton object of TiSDKManager.
        <pre v-highlightA>
          <code>
            addContentView(new TiPanelLayout(this).init(TiSDKManager.getInstance()),
            new FrameLayout.LayoutParams(ViewGroup.LayoutParams.MATCH_PARENT, ViewGroup.LayoutParams.MATCH_PARENT));
          </code>
        </pre>
      </li>
      <li>
        （3）call  <span>renderOESTexture</span> function to render GL_TEXTURE_EXTERNAL_OES texture and return GL_TEXTURE_2D texture.
        <pre v-highlightA>
          <code>
            int textureId = TiSDKManager.getInstance().renderOESTexture(
              oesTextureId, //oes texture id
              imageWidth, //image width
              imageHeight, //image height
              rotation, //TiRotation enum type, the angel at which the image rotates clockwise
              isMirror//whether the image is mirrored
            );
          </code>
        </pre>
      </li>
      <li>
        （4call <span>renderTexture2D</span
        >function to render GL_TEXTURE_2D texture and return GL_TEXTURE_2D texture.
        <pre v-highlightA>
          <code>
            int textureId = TiSDKManager.getInstance().renderTexture2D(
              texture2D,//2D texture id
              imageWidth,//image width
              imageHeight,//image height
              rotation,//TiRotation enum type, the angel at which the image rotates clockwise
              isMirror//whether the image is mirrored
            );
          </code>
        </pre>
      </li>
      <li>
        （5call
        <span>renderPixels</span>
       function to render video frame of byte.
        <pre v-highlightA>
          <code>
            TiSDKManager.getInstance().renderPixels(
              data,//video frame array
              format,//video frame format，NV21 or RGBA
              imageWidth,//image width
              imageHeight,//image height
              rotation,//TiRotation enum type, the angel at which the image rotates clockwise
              isMirror//whether the image is mirrored
            );
          </code>
        </pre>
      </li>
    </ul>
    <h3>6.3 Release</h3>
    <p>
      When rendering is finished, you should release rendering resources, otherwise it will cause memory leakage. Generally, call 
      <span>destroy</span> function to release resources with the release of callback interface of video frame or the end of life cycle of current 
      <span>Activity</span>or<span>Fragment</span>
      <pre v-highlightA>
          <code>
            TiSDKManager.getInstance().destroy();
          </code>
      </pre>
    </p>
    <h3>6.4 Deploy resources to your own servers</h3>
    <p>All resources are enabled to be deployed to your own servers. The methods are as follows:</p>
    <pre v-highlightA>
      <code>
        TiSDK.setStickerUrl(String url);//sticker resources' root directory
        TiSDK.setStickerThumbUrl(String url);//sticker thumbnails' root directory
        TiSDK.setInteractionUrl(String url);//interaction sticker resources' root directory
        TiSDK.setInteractionThumbUrl(String url);//interaction sticker thumbnails' root directory
        TiSDK.setGiftUrl(String url);//gift resources' root directory
        TiSDK.setGiftThumbUrl(String url);//gift thumbnails' root directory
        TiSDK.setWatermarkUrl(String url);//watermark resources' root directory
        TiSDK.setWatermarkThumbUrl(String url);//watermark thumbnails' root directory
        TiSDK.setMaskUrl(String url);//mask resources' root directory
        TiSDK.setMaskThumbUrl(String url);//mask thumbnails' root directory
        TiSDK.setGreenScreenUrl(String url);//greenscreen resources' root directory
        TiSDK.setGreenScreenThumbUrl(String url);//greenscreen thumbnails' root directory
        TiSDK.setMakeupUrl(String url);//makeup resources' root directory
        TiSDK.setMakeupThumbUrl(String url);//makeup thumbnails' root directory
        TiSDK.setPortraitUrl(String url);//portrait resources' root directory
        TiSDK.setPortraitThumbUrl(String url);//portrait thumbnails' root
      </code>
    </pre>
    <h3>6.5 Log function</h3>
    <p>use the following function to enable or disable log function.</p>
    <pre v-highlightA>
      <code>
       TiSDK.setLog(boolean enable);//true:enable false:disable
      </code>
    </pre>
  </div>
</div>
  
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
h3 {
  font-size: 18px;
  font-weight: 400;
  color: #3c4858;
  margin: 20px 0;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
span {
  color: #e91e63;
}
img {
  width: 431px;
  margin: 20px 60px;
}
li {
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
.ul_style {
  padding-left: 10px;
  li {
    line-height: 32px;
    list-style: disc inside none;
  }
  a {
    color: #4c8cf5;
    text-decoration: underline;
  }
}
</style>